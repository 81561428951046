import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import {fetchUnavailableDates, fetchPropertyPrices, fetchPropertyServices} from './api.js';
import {createElement} from './dom.js';

import IMask from 'imask';

export function initForm() {
  document.addEventListener('DOMContentLoaded', renderForm);
}

function renderForm() {
  let propertyId = null;
  let datepicker = null;

  const houseSelect = document.querySelector('#house');
  const servicesContainer = document.querySelector('#services-container');
  const bookButtons = document.querySelectorAll('.book-property');
  const calendarContainer = document.querySelector('#calendar-container');

  const calendarTitle = createElement('h4', {textContent: 'Выберите дату'});


  const calendarInput = createElement('input', {
    id: 'inline',
    type: 'hidden',
    readOnly: true,
    placeholder: 'Выберите дату',
  });
  calendarContainer.appendChild(calendarTitle);
  calendarContainer.appendChild(calendarInput);

  bookButtons.forEach(button => button.addEventListener('click', () => handlePropertySelection(button.dataset.propertyId)));
  houseSelect.addEventListener('change', () => handlePropertySelection(houseSelect.value));

  async function handlePropertySelection(selectedPropertyId) {
    propertyId = selectedPropertyId;
    houseSelect.value = propertyId;
    servicesContainer.innerHTML = '';
    await initializeDatePickerWithServices(propertyId);
  }

  async function initializeDatePickerWithServices(propertyId) {
    const today = new Date();
    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);

    const unavailableDates = await fetchUnavailableDates(propertyId) || [];
    if (datepicker) datepicker.destroy();

    datepicker = new AirDatepicker(calendarInput, {
      startDate: today,
      minDate: today,
      maxDate: nextYear,
      showOtherMonths: false,
      inline: true,

      onSelect: ({date}) => {
        if (date) loadPropertyData(propertyId, date);
      },
      onBeforeSelect: ({date}) => !unavailableDates.includes(date.toLocaleDateString('en-CA')),
      onRenderCell: ({date}) => {
        if (unavailableDates.includes(date.toLocaleDateString('en-CA'))) {
          return {disabled: true};
        }
      }
    });
  }

  async function loadPropertyData(propertyId, date) {
    servicesContainer.innerHTML = '';

    const [prices, services] = await Promise.all([
      fetchPropertyPrices(propertyId, date),
      fetchPropertyServices(propertyId, date),
    ]);

    renderPrices(prices, servicesContainer);
    renderServices(services, servicesContainer);
    addContactFields(servicesContainer);
    createSubmitButton(servicesContainer);
  }
}


function renderPrices(prices, container) {
  const title = createElement('h4', {textContent: 'Выберите время аренды'});
  container.appendChild(title);

  const formRowDiv = createElement('div', {classList: ['form-row']});
  const pricesContainer = createElement('div', {classList: ['prices-container']});

  if (prices.price_per_night && prices.price_per_6_hours) {
    pricesContainer.appendChild(createPriceRadioButton('🌙 Ночь', prices.price_per_night, 'price-night', true));
    pricesContainer.appendChild(createPriceRadioButton('🕕6 часов', prices.price_per_6_hours, 'price-6-hours'));
  } else if (prices.price_per_night) {
    pricesContainer.appendChild(createPriceRadioButton('🌙 Ночь', prices.price_per_night, 'price-night', true));
  } else if (prices.price_per_6_hours) {
    pricesContainer.appendChild(createPriceRadioButton('🕕6 часов', prices.price_per_6_hours, 'price-6-hours', true));
  }

  formRowDiv.appendChild(pricesContainer);
  container.appendChild(formRowDiv);
}


function createPriceRadioButton(labelText, price, id, checked = false) {
  const input = createElement('input', {
    type: 'radio',
    id: id,
    name: 'price',
    value: price,
    checked: checked,
  });

  const label = createElement('label', {htmlFor: id, textContent: `${labelText} - ${price} руб`});

  const radioDiv = createElement('div', {classList: ['form_radio_btn', 'form-cell']}, input, label);

  return radioDiv;
}


function renderServices(services, container) {
  const title = createElement('h4', {textContent: 'Выберите дополнительные услуги'});
  container.appendChild(title);

  const servicesByType = services.reduce((acc, service) => {
    if (!acc[service.type]) acc[service.type] = [];
    acc[service.type].push(service);
    return acc;
  }, {});

  Object.entries(servicesByType).forEach(([type, servicesList]) => {
    servicesList.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    const groupDiv = createServiceGroup(type, servicesList);
    container.appendChild(groupDiv);
  });
}


function createServiceGroup(type, servicesList) {
  const formRowDiv = createElement('div', {classList: ['form-row']});
  const groupDiv = createElement('div', {classList: ['form-cell']});

  servicesList.forEach(service => {
    groupDiv.appendChild(createServiceRadioButton(type, service));
  });

  formRowDiv.appendChild(groupDiv);

  return formRowDiv;
}

function createServiceRadioButton(type, service) {
  const radioId = `service-${type}-${service.name}`;

  const input = createElement('input', {
    type: 'radio',
    id: radioId,
    name: `service[${type}]`,
    value: service.id,
    checked: service.price === "0",
  });

  const label = createElement('label', {
    htmlFor: radioId,
    textContent: `${service.name} ${service.price} руб`,
  });

  const radioDiv = createElement('div', {classList: ['form_radio_btn', 'form-cell']}, input, label);

  return radioDiv;
}


function addContactFields(container) {
  const formRowDiv = createElement('div', {classList: ['form-row']});

  const nameInput = createElement('input', {
    required: true,
    placeholder: 'Введите имя и фамилию',
    type: 'text',
    id: 'text-required',
    name: 'full_name',
  });

  const nameLabel = createElement('label', {
    htmlFor: 'text-required',
    textContent: 'Введите имя и фамилию',
  });

  const nameInputDiv = createElement('div', {classList: ['form-cell']}, nameLabel, nameInput);

  const telInput = createElement('input', {
    required: true,
    placeholder: '+7 (XXX) XXX-XX-XX',
    type: 'tel',
    id: 'tel-required',
    name: 'phone',
  });

  const telLabel = createElement('label', {
    htmlFor: 'tel-required',
    textContent: 'Введите телефон',
  });

  const telInputDiv = createElement('div', {classList: ['form-cell']}, telLabel, telInput);

  formRowDiv.appendChild(nameInputDiv);
  formRowDiv.appendChild(telInputDiv);

  container.appendChild(formRowDiv);

  addPhoneMask(telInput);
}


function addPhoneMask(input) {
  const maskOptions = {
    mask: '+{7} (000) 000-00-00'
  };
  IMask(input, maskOptions);
}

function createSubmitButton(container) {
  if (document.querySelector('#submit-container')) return;

  const submitButton = createElement('input', {
    id: 'submit-button',
    type: 'submit',
    value: 'Забронировать',
    onclick: handleSubmit,
  });

  // Текст условий с ссылками
  const disclaimerText = createElement('p', {
    classList: ['disclaimer-text'],
    innerHTML: `Нажимая кнопку Забронировать, вы подтверждаете, что ознакомлены с условиями 
    <a href="/pages/user-agreement" target="_blank">Пользовательского соглашения</a> и 
    <a href="/pages/privacy-policy" target="_blank">Политики конфиденциальности</a>, а также соглашаетесь с их условиями.`
  });

  const submitButtonDiv = createElement('div', {
    classList: ['form-cell', 'btn-group']
  }, submitButton);

  const submitContainer = createElement('div', {
    classList: ['form-row'],
    id: 'submit-container'
  }, disclaimerText, submitButtonDiv);

  container.appendChild(submitContainer);
}


async function handleSubmit(event) {
  event.preventDefault();

  const selectedDate = document.getElementById("inline").value;

  const [day, month, year] = selectedDate.split('.');

  // Создаем строку в формате, который понимает конструктор Date (YYYY-MM-DD)
  const dateStringISO = `${year}-${month}-${day}`; // Получится "2024-01-14"
  const date = new Date(dateStringISO);


  const selectedPriceInput = document.querySelector('input[name="price"]:checked');
  const selectedPriceId = selectedPriceInput ? selectedPriceInput.id : null;

  let nextDate;

  if (selectedPriceId === 'price-6-hours') {
    // Для "6 часов" дата не меняется
    nextDate = `${day}.${month}.${year}`;
  } else {
    // Для "Ночь" добавляем один день
    date.setDate(date.getDate() + 1);

    const nextDay = String(date.getDate()).padStart(2, '0');
    const nextMonth = String(date.getMonth() + 1).padStart(2, '0');
    const nextYear = date.getFullYear();

    nextDate = `${nextDay}.${nextMonth}.${nextYear}`;
  }

  const propertyId = document.getElementById("house").value;
  
  const selectedServiceInputs = document.querySelectorAll('input[name^="service"]:checked');
  const selectedServiceIds = Array.from(selectedServiceInputs).map(el => el.value);

  const fullName = document.querySelector('#text-required')?.value;
  const phone = document.querySelector('#tel-required')?.value;

  if (!selectedDate || !selectedPriceId || !fullName || !phone) {
    alert('Пожалуйста, заполните все обязательные поля.');
    return;
  }
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const formData = new FormData();
  formData.append("csrf_token", csrfToken);
  formData.append('property_id', propertyId);
  formData.append('date_from', selectedDate);
  formData.append('date_to', nextDate);
  formData.append('price_type', selectedPriceId);
  formData.append('full_name', fullName);
  formData.append('phone', phone);
  selectedServiceIds.forEach(serviceId => {
    formData.append('additional_services_ids[]', serviceId);
  });


  try {
    const response = await fetch('/reservations', {
      method: 'POST',
      body: formData
    });

    if (response.ok) {

      if (response.redirected) {
        window.location.href = response.url;
      } else {
        alert('Бронирование успешно отправлено!');
      }
    } else if (response.status === 303 || response.status === 302) {

      const location = response.headers.get('Location');
      if (location) {
        window.location.href = location;
      } else {
        alert('Ошибка при бронировании.');
      }
    } else {
      console.log(response)
      alert('Ошибка при бронировании.');
    }
  } catch (error) {
    console.error('Ошибка при отправке бронирования:', error);
    alert('Ошибка при отправке данных.');
  }
}
