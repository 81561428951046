// Вспомогательная функция для создания элементов DOM
export function createElement(tag, options = {}, ...children) {
  const element = document.createElement(tag);

  for (const [key, value] of Object.entries(options)) {
    if (key === 'classList') {
      element.classList.add(...value);
    } else if (key === 'attributes') {
      for (const [attrName, attrValue] of Object.entries(value)) {
        element.setAttribute(attrName, attrValue);
      }
    } else if (key.startsWith('on') && typeof value === 'function') {
      element.addEventListener(key.slice(2).toLowerCase(), value);
    } else {
      element[key] = value;
    }
  }

  for (const child of children) {
    if (typeof child === 'string') {
      element.appendChild(document.createTextNode(child));
    } else if (child) {
      element.appendChild(child);
    }
  }

  return element;
}
