import '../sass/style.scss';


import {initMenu} from './menu.js';
import {initTabs} from './tabs.js';
import {initModal} from './modal.js';
import {initCarousel} from './carousel.js';
import {initForm} from './booking.js'


initMenu();
initTabs();
initModal();
new WOW().init();
initCarousel();
initForm();

