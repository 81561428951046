export async function fetchUnavailableDates(propertyId) {
  try {
    const response = await fetch(`/availability-dates?property_id=${propertyId}`);
    const data = await response.json();
    return data.unavailable_dates;
  } catch (error) {
    console.error('Ошибка при получении недоступных дат:', error);
    return [];
  }
}

export async function fetchPropertyPrices(propertyId, date) {
  try {
    const dateStr = date.toLocaleDateString('en-CA');
    const response = await fetch(`/property-prices?property_id=${propertyId}&date=${dateStr}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Ошибка при получении цен:', error);
    return {};
  }
}

export async function fetchPropertyServices(propertyId, date) {
  try {
    const dateStr = date.toLocaleDateString('en-CA');
    const response = await fetch(`/property-services?property_id=${propertyId}&date=${dateStr}`);
    const data = await response.json();
    return data.additional_services;
  } catch (error) {
    console.error('Ошибка при получении услуг:', error);
    return [];
  }
}
