export function initModal() {
  const openModal = document.querySelectorAll('.trigger');
  const modalBackground = document.querySelector('.modalBackground');
  const modalClose = document.querySelector('.modalClose');
  const modalWindow = document.querySelector('.modalWindow');

  // Ждём, пока DOM полностью загрузится, перед применением модалок
  document.addEventListener("DOMContentLoaded", function() {
    // Скрываем все модалки до инициализации JS
    const modalRools = document.querySelector('.modalRools');
    const modalService = document.querySelector('.modalService'); // Объявляем переменную один раз

    // Удаляем класс .open, если он есть
    modalRools.classList.remove('open');
    modalService.classList.remove('open');

    // Далее, обработчики для открытия и закрытия модалок
    openModal.forEach(i => {
      i.addEventListener('click', function () {
        modalBackground.style.display = "block";  // Фоновая модалка остается блоком
        modalWindow.style.display = "block";      // Окно модалки также отображается
        document.querySelector('body').style.overflow = 'hidden'; // Блокируем прокрутку
        document.querySelector('body').style.marginRight = '13px'; // Избегаем проблемы с полосой прокрутки
      });
    });

    // Закрытие модалки
    modalClose.addEventListener("click", function () {
      modalBackground.style.display = "none"; // Скрываем фон
      modalWindow.style.display = "none"; // Скрываем окно
      document.querySelector('body').style.overflow = ''; // Восстанавливаем прокрутку
      document.querySelector('body').style.marginRight = '0'; // Восстанавливаем отступ
    });

    // Закрытие при клике на фон
    modalBackground.addEventListener("click", function (event) {
      if (event.target === modalBackground) {
        modalBackground.style.display = "none";
        modalWindow.style.display = "none";
        document.querySelector('body').style.overflow = '';
        document.querySelector('body').style.marginRight = '0';
      }
    });

    // Обработка модалки с правилами
    const openModalRools = document.querySelector('.rools');
    const closeRools = document.querySelectorAll('.closeRools');
    openModalRools.addEventListener('click', function () {
      modalRools.classList.add('open'); // Добавляем класс .open
    });

    closeRools.forEach(item => {
      item.addEventListener('click', function () {
        modalRools.classList.remove('open'); // Убираем класс .open
      });
    });

    // Обработка модалки с сервисами
    const openModalService = document.querySelector('.service');
    const closeService = document.querySelectorAll('.closeService');
    // modalService уже объявлена выше, не нужно её повторно объявлять
    openModalService.addEventListener('click', function () {
      modalService.classList.add('open'); // Добавляем класс .open
    });

    closeService.forEach(item => {
      item.addEventListener('click', function () {
        modalService.classList.remove('open'); // Убираем класс .open
      });
    });
  });
}
